import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { Navigation } from '../'
import config from '../../../utils/siteConfig'

/**
 * Default Header
 *
 */

const DefaultHeader = ({ data, isHome, isHeader, tag, author, countOfArticle }) => {
  const site = data.allGhostSettings.edges[0].node
  const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
  const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null

  return (
    <>
     <header className="site-head" style={{ ...site.cover_image && { backgroundImage: `url(${site.cover_image})` } }}>
       <div className="container">
         <div className="site-mast">
           <div className="site-mast-left">
             <Link to="/">
               {site.logo ?
                   <img className="site-logo" src="https://seeyoudoc.s3-ap-southeast-1.amazonaws.com/logo/official-logo-white-1.svg" alt={site.title} />
                    : <Img fixed={data.file.childImageSharp.fixed} alt={site.title} />
              }
              </Link>
            </div>

            { !isHome ?
               <div className="site-nav-center" style={{color: 'blue'}}>
                 {/* The navigation items as setup in Ghost */}
                 <Navigation data={site.navigation} navClass="site-nav-item" /> 
               </div>:
            null}
            
            <div className="site-mast-right">
            { site.twitter && <a href={ twitterUrl } className="site-nav-item" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/twitter.svg" alt="Twitter" /></a>}
            { site.facebook && <a href={ facebookUrl } className="site-nav-item" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/facebook.svg" alt="Facebook" /></a>}
            <a href="https://www.instagram.com/seeyoudoc/" className="site-nav-item" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/instagram-white.svg" alt="Instagram" /></a>
           </div>
          </div>
              { isHome ?
                <div className="site-banner">
                    <h1 className="site-banner-title">{site.title}</h1>
                  <p className="site-banner-desc">{site.description}</p>
                </div> :
                  null}

              { isHeader == 'tag' ?
                <div className="site-banner">
                    <h1 className="site-banner-title">{tag? tag.name : "Tag"}</h1>
                  <p className="site-banner-desc">{tag? tag.description : "Keywords"}</p>
                </div> :
                  null}

                  { isHeader == 'author' ?
                    <>
                      {author? 
                        <div className="site-banner center">
                          <div className="author-header-image m-0">
                            {author.profile_image && <img src={author.profile_image} alt={author.name} />}
                          </div>
                          <h1 className="site-banner-title">{author.name}</h1>
                          <small className="site-banner-count-of-article">{countOfArticle} articles posted</small>
                        </div> 
                      :
                        <div className="site-banner">
                          <h1 className="site-banner-title">Author</h1>
                          <p className="site-banner-desc">Staff. Editors. Writers</p>
                        </div>
                      }
                    </>
                :
                null}

              { isHome ?
                <nav className="site-nav">
                  <div className="site-nav-left mt-n188">
                    {/* The navigation items as setup in Ghost */}
                    <Navigation data={site.navigation || 0} navClass="site-nav-item" /> 
                 </div>
                </nav>:
              null}
           </div>
        </header>
    </>
  )
}

DefaultHeader.propTypes = {
  isHome: PropTypes.bool,
  data: PropTypes.shape({
    file: PropTypes.object,
    allGhostSettings: PropTypes.object.isRequired,
  }).isRequired,
}

export default DefaultHeader
