import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const TagCard = ({ tag, index }) => {
    const url = `tag/${tag.slug}/`
    return (
      <Link to={url} key={index} className="post-card"> 
        <div className="post-card-content">
          <div className="post-card-content-link">
            <header className="post-card-header">
              <h2 className="post-card-title">{tag.name}</h2>
            </header>
            <section className="post-card-excerpt overflow-wrap">{tag.description}</section>
          </div>
          <footer className="post-card-footer">
            <div className="post-card-footer-left">
              <span>{ tag.postCount } articles posted</span>
            </div>
          </footer>
        </div>
      </Link>
    )
}

TagCard.propTypes = {
    tag: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        postCount: PropTypes.number,
    }).isRequired,
}

export default TagCard
