import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Navigation } from '.'
import  DefaultHeader  from '../../components/common/site-header/DefaultHeader'
import  PostHeader  from '../../components/common/site-header/PostHeader'
import config from '../../utils/siteConfig'

// Styles
import '../../styles/index.scss'

/**
* Main layout component
*
* The Layout component wraps around each page and template.
* It also provides the header, footer as well as the main
* styles, and meta data for each page.
*
*/
const DefaultLayout = ({ data, children, bodyClass, isHome, isPost, isHeader, tag, author, countOfArticle}) => {
    const site = data.allGhostSettings.edges[0].node
    const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
    const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null

    return (
    <>
        <Helmet>
            <html lang={site.lang} />
            <meta
              name="google-site-verification"
              content="gIY89pGNCmh0zHx6nqysnOWwj4n2bkOZ2FAZs5n60qY"
            />
            <style type="text/css">{`${site.codeinjection_styles}`}</style>
            <body className={bodyClass} />
        </Helmet>

        <div className="viewport">
            <div className="viewport-top">
              {/* The main header section on top of the screen */}
              { !isPost ?
                    <DefaultHeader data={data} isHome={isHome} tag={isHeader == 'tag' && tag} author={isHeader == 'author' && author} isHeader={isHeader} countOfArticle={countOfArticle} />
                   :
                    <PostHeader data={data} isHome={isHome}/>
							} 
                
                <main className="site-main">
                    {/* All the main content gets inserted here, index.js, post.js */}
                    {children}
                </main>

            </div>

            <div className="viewport-bottom">
                {/* The footer at the very bottom of the screen */}
                <footer className="site-foot">
                    <div className="site-foot-nav container">
                        <div className="site-foot-nav-left">
                            <Link to="/">{site.title}</Link> © 2019
                        </div>
                        <div className="site-foot-nav-right">
                          Copyright © {site.title} 2019. All rights reserved
                        </div>
                    </div>
                </footer>

            </div>
        </div>

    </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    isHome: PropTypes.bool,
    isPost: PropTypes.bool,
    isHeader: PropTypes.string,
    data: PropTypes.shape({
        file: PropTypes.object,
        allGhostSettings: PropTypes.object.isRequired,
    }).isRequired,
    tag: PropTypes.shape({
         name: PropTypes.string.isRequired,
         description: PropTypes.string,
    }),
    author: PropTypes.shape({
      name: PropTypes.string.isRequired,
      cover_image: PropTypes.string,
      profile_image: PropTypes.string,
      bio: PropTypes.string,
    }),
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query GhostSettings {
                allGhostSettings {
                    edges {
                        node {
                            ...GhostSettingsFields
                        }
                    }
                }
                file(relativePath: {eq: "ghost-icon.png"}) {
                    childImageSharp {
                        fixed(width: 30, height: 30) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default DefaultLayoutSettingsQuery
