import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import { Navigation } from '../'
import config from '../../../utils/siteConfig'

/**
 * Post Header
 */

const PostHeader = ({ data }) => {
  const site = data.allGhostSettings.edges[0].node
  const twitterUrl = site.twitter ? `https://twitter.com/${site.twitter.replace(/^@/, ``)}` : null
  const facebookUrl = site.facebook ? `https://www.facebook.com/${site.facebook.replace(/^\//, ``)}` : null

  return (
    <>
    <header className="site-head" style={{background:'white', borderBottom: '1px solid #dee2e6'}}>
      <div className="container">
        <div className="site-mast">
          <div className="site-mast-left">
            <Link to="/">
              {site.logo ?
                  <img className="site-logo" src='https://seeyoudoc.s3-ap-southeast-1.amazonaws.com/logo/official-logo-blue-1.svg' alt={site.title} />
                  : <Img fixed={data.file.childImageSharp.fixed} alt={site.title} />
              }
            </Link>
          </div>

          <div className="site-nav-center" style={{color: 'blue'}}>
            {/* The navigation items as setup in Ghost */}
            <Navigation data={site.navigation} navClass="site-nav-item text-dark " /> 
          </div>
          <div className="site-mast-right">
            { site.twitter && <a href={ twitterUrl } className="site-nav-item" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="https://seeyoudoc.s3-ap-southeast-1.amazonaws.com/icons/twitter-dark.svg" alt="Twitter" /></a>}
            { site.facebook && <a href={ facebookUrl } className="site-nav-item" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="https://seeyoudoc.s3-ap-southeast-1.amazonaws.com/icons/facebook-dark.svg" alt="Facebook" /></a>}
            <a href="https://www.instagram.com/seeyoudoc/" className="site-nav-item" target="_blank" rel="noopener noreferrer"><img className="site-nav-icon" src="/images/icons/instagram-dark.svg" alt="Instagram" /></a>
          </div>
        </div>
      </div>
    </header>
    </>
  )
}

PostHeader.propTypes = {
  data: PropTypes.shape({
    file: PropTypes.object,
    allGhostSettings: PropTypes.object.isRequired,
  }).isRequired,
}

export default PostHeader
