import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const AuthorCard = ({ author, index }) => {
    const url = `author/${author.slug}/`
    return (
      <Link to={url} key={index} className="post-card"> 
        {author.cover_image ?
            <div className="post-card-image m-0" style={{
              backgroundImage: `url(${author.cover_image})`,
            }}></div>
          :
            <div className="post-card-image m-0 default" style={{
              backgroundImage: `url(https://seeyoudoc.s3-ap-southeast-1.amazonaws.com/default-image.svg)` ,
              backgroundPosition: 'center',
              backgroundSize: '300px 200px',
            }}></div>
        }
      <div className="author-profile">
        {author.profile_image?  
            <img src={author.profile_image} alt={author.name} />
            :
            <img src="https://seeyoudoc.s3-ap-southeast-1.amazonaws.com/d-default.png" alt={author.name} />
        }
      </div>
        <div className="post-card-content">
          <div className="post-card-content-link">
            <header className="post-card-header">
              <h2 className="post-card-title">{author.name}</h2>
            </header>
            <section className="post-card-excerpt overflow-wrap">{author.bio}</section>
          </div>
          <footer className="post-card-footer">
            <div className="post-card-footer-left">
              <span>{author.postCount} articles posted</span>
            </div>
          </footer>
        </div>
      </Link>
    )
}

AuthorCard.propTypes = {
    author: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        profile_image: PropTypes.string,
        cover_image: PropTypes.string,
        postCount: PropTypes.number,
    }).isRequired,
}

export default AuthorCard

export const pageQuery = graphql`
    {
      allGhostPost{
            edges {
                node {
                ...GhostPostFields
                }
            }
        }
    }
`
