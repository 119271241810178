import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { Tags } from '@tryghost/helpers-gatsby'

const SuggestedPost = ({ post }) => {
    const url = `/${post.slug}/`
    return (
      <Link to={url} className="post-card">
        {post.feature_image ?
            <div className="post-card-image" style={{
              backgroundImage: `url(${post.feature_image})`,
            }}></div>
            :
            <div className="post-card-image default" style={{
              backgroundImage: `url(https://seeyoudoc.s3-ap-southeast-1.amazonaws.com/default-image.svg)` ,
              backgroundPosition: 'center',
              backgroundSize: '300px 200px',
            }}></div>
        }
        <div className="post-card-content">
          <div className="post-card-content-link">
            <header className="post-card-header">
                {post.tags && <div className="post-card-tags"> <Tags post={post} visibility="public" autolink={false} /></div>}
                {post.featured && <span>Featured</span>}
                <h2 className="post-card-title">{post.title}</h2>
              </header>
              <section className="post-card-excerpt overflow-wrap">{post.excerpt}</section>
          </div>
          <footer className="post-card-footer">
            <div className="post-card-footer-left">
              <div className="post-card-avatar custom-post-card-avatar">
                  {post.primary_author.profile_image ?
                      <img className="author-profile-image" src={post.primary_author.profile_image} alt={post.primary_author.name}/> :
                      <img className="default-avatar" src="/images/icons/avatar.svg" alt={post.primary_author.name}/>
                  }
                </div>
              <div className="d-flex flex-column">
                <span className="text-capitalize">{ post.primary_author.name }</span>
                <span>{ post.published_at }</span>
              </div>
            </div>
          </footer>
        </div>
      </Link>
    )
}

SuggestedPost.propTypes = {
  post: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string.isRequired,
    feature_image: PropTypes.string,
    published_at: PropTypes.string.isRequired,
    primary_author: PropTypes.shape({
      name: PropTypes.string.isRequired,
      profile_image: PropTypes.string,
    }).isRequired,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
  }).isRequired
}

export default SuggestedPost
